import { getTags } from "utils/TagUtil";
import {
  getAutomaticStatus,
  getManualSwitchStatus,
} from "utils/AutomaticStatusUtil";
import UploadTypeStatus from "utils/UploadTypeStatus";

export const NEXT_PAGE = "NEXT_PAGE";

export const COMMON_ROUTES = {
  SERVICE_UNAVAILABLE: {
    path: "/service-indisponible",
    component: "ServiceUnavailable",
    tcVars: getTags({
      ch2: "etape-0",
      ch3: "",
      pageName: "etape-0.4-certification_indisponible",
      step: "0.4",
    }),
  },
  URL_NOT_AUTHORIZED: {
    path: "/certification-indisponible",
    component: "ErrorUrlNotAuthorized",
  },
  LOGOUT: {
    path: "/logout",
    componentPath: "Logout",
  },
};

export const PRIVATE_ROUTES = {
  HOME: {
    path: "/",
    component: "Home",
    tcVars: {
      ch2: "etape-1",
      ch3: "",
      pageName: "prerequis",
      step: "1",
    },
  },
  MISSING_COCLICO: {
    path: "/coclico-manquant",
    component: "ErrorMissingCoclico",
    tcVars: {
      ch2: "etape-0",
      ch3: "",
      pageName: "etape-0.1-identification_etablissement_impossible",
      step: "0.1",
    },
  },
  INACTIVE_COCLICO: {
    path: "/coclico-erreur",
    component: "ErrorInactiveCoclico",
    tcVars: {
      ch2: "etape-0",
      ch3: "",
      pageName: "etape-0.2-certification_compte_pro_impossible",
      step: "0.2",
    },
  },
  INVALID_RNA: {
    path: "/rna-invalide",
    component: "ErrorInvalidRna",
    tcVars: {
      ch2: "etape-0",
      ch3: "",
      pageName: "etape-0.2-certification_compte_pro_impossible",
      step: "0.2",
    },
  },
  INACTIVE_SIRET: {
    path: "/siret-erreur",
    component: "ErrorInactiveSiret",
    tcVars: {
      ch2: "etape-0",
      ch3: "",
      pageName: "etape-0.2-certification_compte_pro_impossible",
      step: "0.2",
    },
  },
  COMPANY_NON_DIFFUSIBLE: {
    path: "/non-diffusible-INSEE",
    component: "ErrorCompanyNonDiffusible",
    tcVars: {
      // TODO
      ch2: "etape-0",
      ch3: "",
      pageName: "etape-0.4-entreprise_invalide",
      step: "0.4",
    },
  },
  MISSING_SIRET: {
    path: "/siret-manquante",
    component: "ErrorMissingSiret",
    tcVars: {
      ch2: "etape-0",
      ch3: "",
      pageName: "etape-0.3-informations_incompletes",
      step: "0.3",
    },
  },
  MISSING_ADDRESS: {
    path: "/adresse-manquante",
    component: "ErrorMissingAddress",
    tcVars: {
      ch2: "etape-0",
      ch3: "",
      pageName: "etape-0.3-informations_incompletes",
      step: "0.3",
    },
  },
  CERTIFICATION_INPROGRESS: {
    path: "/certification-erreur1",
    component: "CertificationControlInProgress",
    tcVars: {
      ch2: "etape-0",
      ch3: "",
      pageName: "etape-0.7-compte_pro_en_cours_certif",
      step: "0.7",
    },
  },
  HAS_CERTIFICATION: {
    path: "/certification-erreur2",
    component: "CertifiedAccount",
    tcVars: {
      ch2: "etape-0",
      ch3: "",
      pageName: "etape-0.6-compte_pro_certifie",
      step: "0.6",
    },
  },
  COMPANY_INFO: {
    path: "/verification-societe",
    component: "CompanyInfo",
    tcVars: {
      ch2: "etape-2",
      ch3: "",
      pageName: "verification_information_entreprise",
      step: "2",
    },
  },
  CHOICE_OF_LEGAL_REPRESENTATIVE: {
    path: "/choix-representant-legal",
    component: "ChoiceOfLegalRepresentative",
  },
  UPDATE_USER_INFO: {
    path: "/verification-client",
    component: "UpdateUserInfo",
    tcVars: {
      ch2: "etape-3",
      ch3: "",
      pageName: "verification_information_personnelles",
      step: "3",
    },
  },
  CHECK_PROCURATION: {
    path: "/verification-procuration",
    component: "VerifyProcuration",
  },
  SUB_ENTRY: {
    path: "/certification-rl-subdelegataire",
    component: "SubHome",
    tcVars: {
      ch2: "etape-5.1",
      ch3: "",
      pageName: "etape-sub_2-demande_certif_sub",
      step: "5.1",
    },
  },
  AUTOMATIC_SUCCESS: {
    path: "/compte-certifie",
    component: "CertificationSuccessAutomatic",
    tcVars: {
      ch2: "etape-10",
      ch3: "",
      pageName: "etape-10.1-resultat_certif_auto",
      step: "10.1",
    },
  },
  ID_ANALYSIS: {
    path: "/analyse-pi",
    component: "IdAnalysis",
    tcVars: {
      ch2: "etape-8",
      ch3: "",
      pageName: "etape-8-analyse_piece_identite",
      step: "8",
    },
  },
  CHECK_LEGAL_REPRESENTATIVE: {
    path: "/verification-representant-legal",
    component: "VerifyLegalRepresentative",
  },
  ID_SELECT: {
    path: "/choix-piece-identite",
    component: "IdSelection",
    tcVars: () => ({
      ch2: getAutomaticStatus() ? "etape-6" : "etape-5",
      ch3: "",
      pageName: getAutomaticStatus()
        ? "etape-6-selectionner_piece_identite"
        : "etape-5.4-selectionner_piece_identite",
      step: getAutomaticStatus() ? "6" : "5.4",
    }),
  },
  COMPANY_STATUS: {
    path: "/choix-statut-societe",
    component: "CompanyStatus",
    tcVars: {
      ch2: "etape-5",
      ch3: "",
      pageName: "etape-5.2-statut_entreprise",
      step: "5.2",
    },
  },
  UPLOAD: {
    path: "/import/:uploadType",
    component: "SupportingDocumentsUpload",
    tcVars: ({ location }) => {
      const uploadType = location.pathname.replace("/import/", "");
      const piType = location.state;

      if (uploadType === UploadTypeStatus.ID) {
        if (piType.access === "IDENTITY_IDCARD") {
          return {
            ch2: getAutomaticStatus() ? "etape-7" : "etape-5",
            ch3: "",
            pageName: `etape-${
              getAutomaticStatus() ? "7.1" : "5.5"
            }-importer_carte_identite`,
            step: getAutomaticStatus() ? "7.1" : "5.5",
          };
        }

        if (piType.access === "IDENTITY_PASSPORT") {
          return {
            ch2: getAutomaticStatus() ? "etape-7" : "etape-5",
            ch3: "",
            pageName: `etape-${
              getAutomaticStatus() ? "7.2" : "5.5"
            }-importer_passeport`,
            step: getAutomaticStatus() ? "7.2" : "5.5",
          };
        }

        if (piType.access === "IDENTITY_RESIDENTPERMIT") {
          return {
            ch2: getAutomaticStatus() ? "etape-7" : "etape-5",
            ch3: "",
            pageName: `etape-${
              getAutomaticStatus() ? "7.3" : "5.5"
            }-importer_titre_de_sejour`,
            step: getAutomaticStatus() ? "7.3" : "5.5",
          };
        }
      }

      return {
        ch2: "etape-5",
        ch3: "",
        pageName: "etape-5.3-justificatifs_entreprise",
        step: "5.3",
      };
    },
  },
  SUB_ID_SELECT: {
    path: "/certification-rl-subdelegataire/choix-piece-identite",
    component: "SubIdSelection",
    tcVars: {
      ch2: "etape-5.1",
      ch3: "",
      pageName: "etape-sub_3-demande_pi_subdelegataire",
      step: "5.1",
    },
  },
  SUB_RL_ID_SELECT: {
    path: "/certification-rl-subdelegataire/choix-piece-identite-representant-legal",
    component: "SubRLIdSelection",
    tcVars: {
      ch2: "etape-5.1",
      ch3: "",
      pageName: "etape-sub_5-demande_pi_RL",
      step: "5.1",
    },
  },
  SUB_UPLOAD: {
    path: "/certification-rl-subdelegataire/import/:uploadType",
    component: "SubUpload",
    tcVars: ({ location }) => {
      const { pathname } = location;
      const uploadType = pathname.substring(pathname.lastIndexOf("/") + 1);

      const piType = location.state;
      const { access } = piType || {};
      const isRlPi = access.includes("_RL");
      const vars = {
        ch2: "etape-5.1",
        ch3: "",
        step: "5.1",
      };
      const piSubStep = isRlPi ? "6" : "4";

      if (
        uploadType === UploadTypeStatus.ID ||
        uploadType === UploadTypeStatus.ID_RL
      ) {
        if (access === "IDENTITY_IDCARD" || access === "IDENTITY_IDCARD_RL") {
          vars.pageName = `etape-sub_${piSubStep}-importer_carte_identite`;
        }

        if (
          access === "IDENTITY_PASSPORT" ||
          access === "IDENTITY_PASSPORT_RL"
        ) {
          vars.pageName = `etape-sub_${piSubStep}-importer_passeport`;
        }

        if (
          access === "IDENTITY_RESIDENTPERMIT" ||
          access === "IDENTITY_RESIDENTPERMIT_RL"
        ) {
          vars.pageName = `etape-sub_${piSubStep}-importer_titre_de_sejour`;
        }
      } else if (uploadType === UploadTypeStatus.SUBDELEGATION) {
        vars.pageName = "etape-sub_7-importer_attestation_subdelegation";
      }

      return vars;
    },
  },
  ID_ILLEGIBLE: {
    path: "/analyse-pi-erreur1",
    component: "NotReadableIdError",
    tcVars: {
      ch2: "etape-9",
      ch3: "",
      pageName: "etape-9.1-piece_identite_non_lisible",
      step: "9.1",
    },
  },
  INVALID_ID: {
    path: "/analyse-pi-erreur2",
    component: "InvalidId",
    tcVars: {
      ch2: "etape-9",
      ch3: "",
      pageName: "etape-9.2-piece_identite_non_valide",
      step: "9.2",
    },
  },
  MULTIPLE_ID_ERROR: {
    path: "/analyse-pi-erreur3",
    component: "OutOfDateIdError",
  },
  MANUAL_SUCCESS: {
    path: "/demande-certification",
    component: "CertificationSuccessManual",
    tcVars: () => {
      const vars = {
        ch2: "etape-10",
        ch3: "",
        pageName: "etape-10.2-resultat_certif_manuel",
        step: "10.2",
      };
      const motifBascule = getManualSwitchStatus();
      if (motifBascule !== undefined) {
        vars.motifBascule = motifBascule;
      }

      return vars;
    },
  },
  UNKNOWN_LEADER: {
    path: "/dirigeant-non-connu",
    component: "UnknownLeader",
    tcVars: () => {
      const vars = {
        ch2: "etape-0",
        ch3: "",
        pageName: "etape-0.9-blocage_rl",
        step: "0.9",
      };
      const motifBascule = getManualSwitchStatus();
      if (motifBascule !== undefined) {
        vars.motifBascule = motifBascule;
      }

      return vars;
    },
  },
  UNKNOWN_COMPANY: {
    path: "/societe-non-connue",
    component: "UnknownCompany",
    tcVars: () => {
      const vars = {
        ch2: "etape-0",
        ch3: "",
        pageName: "etape-0.10-blocage_infos_incompletes",
        step: "0.10",
      };
      const motifBascule = getManualSwitchStatus();
      if (motifBascule !== undefined) {
        vars.motifBascule = motifBascule;
      }

      return vars;
    },
  },
};

export const PUBLIC_ROUTES = {
  HOME_DISCONNECTED: {
    path: "/",
    component: "DisconnectedHome",
    tcVars: getTags({
      ch2: "etape-0",
      ch3: "",
      pageName: "etape-0.5-non_connecte",
      step: "0.5",
    }),
  },
};

export const TEST_MODE_ROUTES = {
  TEST_MODE_HOME: {
    path: "/mode-de-test",
    component: "TestModeHome",
  },
  AUTOMATIC_SUCCESS_TEST: {
    path: "/automatic-success",
    component: "AutomaticSuccess",
  },
  SCREEN_TESTING: {
    path: "/description-simulateur",
    component: "ScreenTesting",
  },
  COMPANY_CONDITIONS: {
    path: "/conditions-entreprise",
    component: "CompanyConditions",
  },
  ACCOUNT_CONDITIONS: {
    path: "/conditions-compte",
    component: "AccountConditions",
  },
  LEGAL_REPRESENTATION_CONDITIONS: {
    path: "/conditions-representant-legal",
    component: "LegalRepresentativeConditions",
  },
  ID_CONDITIONS: {
    path: "/conditions-piece-d-identite",
    component: "IdConditions",
  },
  RESULT_CONDITIONS: {
    path: "/conditions-resultat",
    component: "ResultConditions",
  },
  START_TEST: {
    path: "/commencer-test",
    component: "StartTest",
  },
};
