import axios from "axios";
import { isValidAddress } from "utils/AddressUtil";
import User from "models/User";
import UrlParametersKey from "utils/UrlParametersKey";
import {
  isTestModeAuthorized,
  ACCOUNT_STATUS_OPTIONS,
  COMPANY_STATUS_OPTIONS,
  getAccountStatus,
  getCompanyStatus,
  getIsTestMode,
  getIsAutomaticSuccess,
} from "utils/TestModeParametersUtil";

const invalidCompaniesName =
  (process.env.REACT_APP_INVALID_COMPANIES_NAME &&
    process.env.REACT_APP_INVALID_COMPANIES_NAME.split(",")) ||
  [];

let userPromise = null;
let userId = "";

export default class UserService {
  static refreshUser = () => {
    userPromise = axios
      .get(`${process.env.REACT_APP_CRL_BACK_URL}/users/me`, {
        params: {
          origin: sessionStorage.getItem(UrlParametersKey.ENTRY) || "direct",
        },
      })
      .then((response) => {
        const user = new User(response.data);
        const companyStatus = getCompanyStatus();
        const accountStatus = getAccountStatus();
        if (
          isTestModeAuthorized &&
          getIsTestMode() &&
          !getIsAutomaticSuccess() &&
          (!companyStatus.includes(COMPANY_STATUS_OPTIONS.REAL_PATH) ||
            accountStatus !== ACCOUNT_STATUS_OPTIONS.REAL_PATH)
        ) {
          if (companyStatus.includes(COMPANY_STATUS_OPTIONS.NO_COCLICO)) {
            user.company = null;
          } else if (user.company) {
            if (
              companyStatus.includes(COMPANY_STATUS_OPTIONS.INVALID_COCLICO)
            ) {
              user.company.active = "0";
            }
            if (accountStatus === ACCOUNT_STATUS_OPTIONS.IS_ALREADY_CERTIFIED) {
              user.status = 1;
            }
            if (
              accountStatus === ACCOUNT_STATUS_OPTIONS.CERTIFICATION_IN_PROCESS
            ) {
              user.status = 9;
            }
            if (
              companyStatus.includes(COMPANY_STATUS_OPTIONS.IS_NOT_DIFFUSABLE)
            ) {
              user.company.name =
                invalidCompaniesName && invalidCompaniesName[0];
            }
            if (companyStatus.includes(COMPANY_STATUS_OPTIONS.INVALID_ADRESS)) {
              user.company.address = null;
            }
            if (companyStatus.includes(COMPANY_STATUS_OPTIONS.NO_SIRET)) {
              user.company.siret = null;
            }
          }
        }
        userId = user.userId;
        return user;
      });
    return userPromise;
  };

  static get = () => {
    if (userPromise instanceof Promise) {
      return userPromise;
    }
    return UserService.refreshUser();
  };

  static getUserId = () => userId;

  static updateInfo = ({ familyName, givenName, gender }) =>
    axios.put(`${process.env.REACT_APP_CRL_BACK_URL}/users/me`, {
      familyName,
      givenName,
      gender,
    });

  static validateCompanyInfo = () =>
    axios.put(`${process.env.REACT_APP_CRL_BACK_URL}/users/me/company`, {});

  static hasCoclico = () =>
    UserService.get()
      .then((user) => Boolean(user && user.company))
      .catch(() => false);

  static hasValidCoclico = () =>
    UserService.get()
      .then((user) =>
        Boolean(user && user.company && user.company.active === "1"),
      )
      .catch(() => false);

  static hasCertification = () =>
    UserService.get()
      .then((user) => Boolean(user && user.status === 1))
      .catch(() => false);

  static hasCertificationInProgress = () =>
    UserService.get()
      .then((user) => Boolean(user && user.status === 9))
      .catch(() => false);

  static isNonDiffusibleCompany = () =>
    UserService.get()
      .then((user) =>
        Boolean(
          user?.company?.name
            ? invalidCompaniesName.includes(user.company.name)
            : false,
        ),
      )
      .catch(() => false);

  static hasCompanyInfo = () =>
    UserService.get()
      .then((user) =>
        Boolean(user?.company?.name && isValidAddress(user.company.address)),
      )
      .catch(() => false);

  static hasSiretNumber = () =>
    UserService.get()
      .then((user) => Boolean(user && user.company && user.company.siret))
      .catch(() => false);

  static hasRnaNumber = () =>
    UserService.get()
      .then((user) => Boolean(user && user.company && user.company.rnaNumber))
      .catch(() => false);

  static hasValidRnaNumber = () =>
    UserService.get()
      .then((user) =>
        Boolean(
          user &&
            user.company &&
            user.company.rnaNumber &&
            /^W[0-9]{9}$/.test(user.company.rnaNumber),
        ),
      )
      .catch(() => false);
}
